import { Container } from "./styles";
import { Link } from "react-router-dom";

export function Privacy() {
  return (
    <Container>
      <Link to="/dashboard">Voltar</Link>
      <div>
        <h2>Política de Privacidade do aplicativo FioLibras</h2>
        {/* <br /> */}
        <p>
          1 – Dados Coletados A coleta de dados pelo aplicativo FioLibras tem
          como objetivo único e exclusivo a manutenção e o aprimoramento dos
          serviços disponibilizados, buscando a evolução contínua do aplicativo,
          a fim de oferecer uma melhor experiência para os usuários.
        </p>{" "}
        {/* <br /> */}
        <p>Os dados que podem ser coletados pelo aplicativo são:</p>
        {/* <br /> */}
        <ul>
          <li>Versão do sistema operacional e modelo do dispositivo</li>
          <li>Localização geográfica do usuário</li>
          <li>Endereço IP (Protocolo de Internet)</li>
          <li>Data e hora de acesso</li>
          <li>Dados de suporte ao usuário</li>
        </ul>
        <p>
          O aplicativo não coleta informações pessoais e não envia mensagens
          solicitando informações, tais como senhas e outros dados pessoais.
        </p>{" "}
        <p>
          2 – Utilização dos dados coletados Os dados coletados são utilizados
          para os seguintes propósitos:
        </p>{" "}
        <ul>
          <li>Identificação para correção de erros</li>
          <li>Melhoria de interface e design</li>
          <li>Análise de estatísticas dos usuários que utilizam os serviços</li>
          <li>Apoio na definição de novos recursos do aplicativo</li>
          <li>
            Dar suporte ao usuário em caso de dúvidas sobre o uso do aplicativo
          </li>
        </ul>
        <p>
          Os dados coletados não são usados para fins de rastreamento, não são
          vinculados a outros dados de terceiros sobre o usuário ou o
          dispositivo para fins de publicidade ou medição de publicidade e não
          são usados para publicidade de terceiros ou para outra finalidade.
        </p>{" "}
        <p>
          3 – Compartilhamento dos dados coletados Os dados coletados pelo
          aplicativo são de propriedade exclusiva da Fiocruz, não sendo
          compartilhados com nenhuma outra instituição, exceto para cumprimento
          de exigência legal, regulamentação ou ordem judicial.
        </p>{" "}
        <p>
          4 – Proteção das Informações Pessoais As informações coletadas pelo
          aplicativo são sigilosas e sujeitas a medidas de segurança e
          confidencialidade adequadas para impedir o acesso ou divulgação não
          autorizados, bem como alteração ou destruição indevidas dos dados.
        </p>{" "}
        <p>
          5 – Disposições Gerais A utilização do aplicativo implica no aceite,
          por parte dos usuários, das condições e termos desta política de
          privacidade. A Fiocruz reserva-se o direito de alterar os termos desta
          Política de Privacidade a qualquer momento, mediante comunicação aos
          seus usuários por e-mail ou notificação do aplicativo.
        </p>
      </div>
    </Container>
  );
}
