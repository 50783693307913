import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Report from "./pages/Report";
import { Privacy } from "./pages/Privacy";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/report" component={Report} />
        <Route path="/privacy" component={Privacy} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
