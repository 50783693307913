import { Link } from "react-router-dom";
import logoImg from "../images/logo.svg";
import "../styles/components/sidebar.css";

export default function Sidebar() {
  return (
    <aside className="app-sidebar">
      <header>
        <img src={logoImg} alt="Fiolibras" className="dashboard-logo" />

        <h2>Bem vindo ao Dashboard do Fiolibras</h2>
        <p>Verifique no mapa de onde as pessoas estão acessando.</p>
      </header>

      <main>
        <ul>
          <li>
            <Link to="/">Início</Link>
          </li>
          <li>
            <Link to="/dashboard">Mapa</Link>
          </li>
          <li>
            <Link to="/report">Relatório</Link>
          </li>
          <li>
            <Link to="/privacy">Politica de Privacidade</Link>
          </li>
        </ul>
      </main>

      <footer>
        <strong>Fiocruz</strong>
        <span>Rio de Janeiro</span>
      </footer>
    </aside>
  );
}
