import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: left;

  color: #000000;

  margin-top: 50px;

  > a {
    width: 50%;

    margin-bottom: 30px;
  }

  > div {
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;

    h2 {
      max-width: 100%;
      text-align: center;
    }

    p {
      margin: 15px 0;
    }

    ul {
      li {
        margin-left: 60px;
      }
    }
  }
`;
